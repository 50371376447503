import "./index.scss";
import Header from "./header";
import ApplicationList from "../../application-list";
import { Navigate } from "react-router-dom";

function WelcomeLayout() {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <div className="appcontainer">
        <Header />
        <div id="WelcomeLayout" className="row wlc-layout-container">
          <div className="content col-md-12">
            <ApplicationList />
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeLayout;
