import axios from "axios";
import { envconf } from "../utils/envConfig";

export async function postRefaire(
  body: any
): Promise<{ success: Boolean; msg: string }> {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    axios
      .post(`${envconf.SERVER_URL}/pizzaRemake`, body, config)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          resolve({ success: true, msg: res.data.message });
        } else {
          resolve({ success: false, msg: res.data.message });
        }
      })
      .catch((err) => {
        reject({
          success: false,
          msg: `Error while trying to make Refaire !`,
        });
      });
  });
}
