// CallButton.tsx
import React, { useState } from 'react';

import './index.scss';
import callIcon from '../../assets/call.svg';
import JitsiMeetPopup from '../Jitsi';

function CallButton(): JSX.Element {
  const [showPopup, setShowPopup] = useState(false);

  const handleCall = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <button data-testid="close-button" className="call-button" onClick={handleCall}>
        <img src={callIcon} alt="Call Icon" className="icon" />
      </button>

      <JitsiMeetPopup isOpen={showPopup} onClose={handleClosePopup} />
    </>
  );
}

export default CallButton;
