import ordersLogo from "../../../../assets/sidebar/orders.svg";
import ordersLogoActive from "../../../../assets/sidebar/orders-active.svg";
import historyLogo from "../../../../assets/sidebar/history.svg";
import historyLogoActive from "../../../../assets/sidebar/history-active.svg";
import Deconnexion from "../../../../assets/sidebar/Deconnexion.svg";
import OrderList from "../../../order-list";
import HistoryList from "../../../history-list";

export interface SidebarItemTypes {
  label: string;
  icon: string;
  activeIcon: string;
  display: string;
  component: React.ComponentType | null;
}

export const sidebarEntries: SidebarItemTypes[] = [
  {
    label: "Commandes",
    icon: ordersLogo,
    activeIcon: ordersLogoActive,
    display: "orders",
    component: OrderList,
  },
  {
    label: "Historique",
    icon: historyLogo,
    activeIcon: historyLogoActive,
    display: "history",
    component: HistoryList,
  },
  {
    label: "Déconnexion",
    icon: Deconnexion,
    activeIcon: Deconnexion,
    display: "logout",
    component: null,
  },
];

export const getComponent = (
  sideBarItemsList: SidebarItemTypes[],
  selected = "",
  logout: () => void
) => {
  if (selected === "logout") {
    logout();
    return null;
  }

  return sideBarItemsList.find((item) => item.display === selected)?.component;
};
