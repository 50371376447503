import React, { useState, useEffect } from "react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import {
  OrderStatus,
  OrderType,
  OrdersType,
} from "../../../../services/customTypes";
import { getPhoto } from "../../../../services/photos";
import { getExtrasString } from "../../../../utils/getToppingName";
import { translateSizeOrder } from "../../../../utils/translations";
import Lottie from "lottie-react";
import loadImage from "../../../../assets/lottie-animations/loading.json";


interface PropsType {
  order: OrdersType;
  closeOrderClick?: Function;
}

const OrderHistoryDetails = ({ order, closeOrderClick }: PropsType) => {
  const [pizzaPhotos, setPizzaPhotos] = useState<{ [key: string]: string }>({}); // Define the type of pizzaPhotos
  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    const fetchPizzaPhotos = async () => {
      // Fetch pizza photos when the component mounts or when order changes
      order.orderLine[0].product.forEach(async (orderItem) => {
        let photoUrl = "";
        if (orderItem.name === "PZZ_Custum") {
          photoUrl = (await getPhoto("PZZ_Start")) ?? "";
        } else {
          photoUrl = (await getPhoto(orderItem.name)) ?? "";
        }
        if (photoUrl) {
          setPizzaPhotos((prevPhotos) => ({
            ...prevPhotos,
            [orderItem.name]: photoUrl,
          }));
        }
      });
    };

    fetchPizzaPhotos();
  }, [order]);

  const handlecloseOrderClick = () => {
    closeOrderClick instanceof Function && closeOrderClick(false);
  };
  const handleLoadEnd = () => {
    setLoadingImage(false);
  };
  const extraNames = getExtrasString(order);
  return (
    <div id="OrderDetails" className="lst-order-details-history">
      <header>
        <div className="info-history">
          Commande n°{" "}
          <span className="order-number-history">{order.orderNumber}</span>
          {order.OrderType !== OrderType.DELIVERY ? (
            <div className="order-type-history">Pickup</div>
          ) : (
            <div className="order-type-history green-history">Delivery</div>
          )}
        </div>
        <div className="Btn-x-history">
          {" "}
          {closeOrderClick instanceof Function && (
            <button
              className="close-button-history"
              onClick={handlecloseOrderClick}
            >
              <FontAwesomeIcon icon={icon({ name: "xmark" })} />
            </button>
          )}
        </div>
      </header>
      <main>
        {order.orderLine[0].product.map((orderItem, index) => (
          <div key={index} className="order-item-history">
             {loadingImage && (
              <div className="lottie-wrapper-history">
                <Lottie animationData={loadImage} loop={true} />
              </div>
            )}
            {orderItem?.name == "PZZ_Custum" ? (
              <img
                // src={pizzaPhotos[orderItem?.name]}
                src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=PZZ_Start`}
                alt="item"
                style={{ width: "175px", height: "200px" }}
                onLoad={handleLoadEnd}
              />
            ) : (
              <img
                // src={pizzaPhotos[orderItem?.name]}
                src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=${orderItem?.name}`}
                alt="item"
                style={{ width: "175px", height: "200px" }}
                onLoad={handleLoadEnd}
              />
            )}
            <div className="item-details-history">
              <div className="title-history">
                {orderItem?.quantity ?? 1} x{" "}
                {orderItem?.name?.replace("PZZ_", "Pizza ")}
              </div>
              <div className="size-history">
                {translateSizeOrder(orderItem?.size?.toUpperCase())}
              </div>
              <div className="topping-list-history">
                <span>Topping</span>
                <div className="topping-items-history">
                  {orderItem.toppings?.map((topping, index) => (
                    <div
                      className={`topping-item-history ${
                        order.Status === OrderStatus.CONFIRMED
                          ? "topping-item-confirmed-history"
                          : ""
                      }`}
                      key={index}
                    >
                      {`${topping.quantity} x ${topping.toppingName}`}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
        {order.orderLine[0].extras && order.orderLine[0].extras.length > 0 && (
          <div className="extras-section">
            <span className="extra-title">Extras </span> {extraNames}
          </div>
        )}
      </main>
    </div>
  );
};

export default OrderHistoryDetails;
