

const filterSlice = {
  initialState: "delivery",
  reducers: {
    setFilter: (state : any, action : any) => {
      if (typeof action.payload !== "string") {
        throw new Error("Invalid action payload");
      }
      return action.payload;
    },
  },
};



export default filterSlice;
