import axios from "axios";
import {
  HistoryOrdersResponse,
  OrderLineData,
  OrderStatus,
  OrdersType,
} from "./customTypes";
import { envconf } from "../utils/envConfig";
import jwt_decode from "jwt-decode";

const getAuthToken = () => localStorage.getItem("token") || "";

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getAuthToken()}` },
});

const getDecodedToken = () => {
  const token = getAuthToken();
  return token ? jwt_decode(token) : null;
};

export async function getAllOrders(): Promise<{
  success: Boolean;
  data: OrdersType[];
}> {
  const decodedToken: any = getDecodedToken();

  try {
    const response = await axios.get(
      `${envconf.SERVER_URL}/Orders-store/${decodedToken?.store?.phoneNumber}`,
      getConfig()
    );

    if (response.data.status === 200 && response.data.success) {
      return {
        success: true,
        data: response.data.content.sort(sortByStatusAndDate),
      };
    } else {
      return { success: false, data: [] };
    }
  } catch (error) {
    console.error("Error fetching all orders:", error);
    return { success: false, data: [] };
  }
}

export async function getOrderLine(
  orderId: string
): Promise<{ success: boolean; data: OrderLineData }> {
  try {
    const response = await axios.get(
      `${envconf.SERVER_URL}/OrderLine/${orderId}`,
      getConfig()
    );

    if (response.data.status === 200 && response.data.success) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: {} as OrderLineData };
    }
  } catch (error) {
    console.error(`Error fetching order line for orderId ${orderId}:`, error);
    return { success: false, data: {} as OrderLineData };
  }
}

export async function getHistoryOrders(
  limit: number,
  page: number
): Promise<{ success: boolean; data: HistoryOrdersResponse }> {
  const decodedToken: any = getDecodedToken();

  try {
    const response = await axios.get<{
      status: number;
      success: boolean;
      content: { orders: OrdersType[]; totalPages: number; count: number };
    }>(
      `${envconf.SERVER_URL}/Orders-store/History/${limit}/${page}/${decodedToken?.store?.phoneNumber}`,
      getConfig()
    );

    if (response.data.status === 200 && response.data.success) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: {} as HistoryOrdersResponse };
    }
  } catch (error) {
    console.error("Error fetching history orders:", error);
    return { success: false, data: {} as HistoryOrdersResponse };
  }
}

export async function updateOrderStatus(
  orderID: string,
  status: string
): Promise<{ success: Boolean; msg: string }> {
  try {
    const response = await axios.patch(
      `${envconf.SERVER_URL}/Order/${orderID}/update-status`,
      { Status: status },
      getConfig()
    );

    if (response.data.status === 200 && response.data.success) {
      return { success: true, msg: response.data.message };
    } else {
      return { success: false, msg: response.data.message };
    }
  } catch (error) {
    console.error(`Error updating order status for orderId ${orderID}:`, error);
    return {
      success: false,
      msg: `Error while trying to update Order! OrderID: ${orderID}`,
    };
  }
}

function sortByStatusAndDate(a: OrdersType, b: OrdersType) {
  if (
    a.Status === OrderStatus.CONFIRMED &&
    b.Status !== OrderStatus.CONFIRMED
  ) {
    return -1;
  } else if (
    a.Status !== OrderStatus.CONFIRMED &&
    b.Status === OrderStatus.CONFIRMED
  ) {
    return 1;
  } else if (a.createdAt < b.createdAt) {
    return -1;
  } else if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
