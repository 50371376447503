import { useState } from "react";
import "./index.scss";
import HeaderApp from "./header";
import HeaderHistory from "../app-screen/headerHistory"; // Import the header for the "history" component
import Sidebar from "./sidebar";
import { getComponent, sidebarEntries } from "./sidebar/sidebar-entries";
import { useAuth } from "../../../services/auth";

function ApplicationLayout() {
  const [openMenu, setOpenMenu] = useState(false);
  const { logout } = useAuth();
  const { Render: RenderSideBar, selected } = Sidebar({
    sideBarItemsList: sidebarEntries,
  });

  const Component = getComponent(sidebarEntries, selected, logout);

  const handleCloseSidebar = () => {
    setOpenMenu(false);
  };

  return (
    <div id="ApplicationLayout" className="layout-container">
      {selected === "history" ? ( 
        <HeaderHistory isMenuOpen={openMenu} menuToggle={setOpenMenu} /> 
      ) : (
        <HeaderApp isMenuOpen={openMenu} menuToggle={setOpenMenu} />
      )}

      <div className="content">
        {RenderSideBar(openMenu, handleCloseSidebar)}
        {Component ? (
          <Component />
        ) : (
          <>{`Couldn't find the "${selected}" component!`}</>
        )}
      </div>
    </div>
  );
}

export default ApplicationLayout;
