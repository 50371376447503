import { useState, useEffect } from "react";
import moment from "moment";

import "./index.scss";
import menuHamburger from "../../../../assets/menu-hamburger.svg";
import logo from "../../../../assets/sidebar/logo-small.svg";
import storeLogo from "../../../../assets/sidebar/store-active.svg";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  useSelector, useStore } from "react-redux";
import { OrderType } from "../../../../services/customTypes";

interface HeaderPropTypes {
  isMenuOpen: Boolean;
  menuToggle: Function;
}
const Date = moment().format("dddd ,  D MMMM YYYY - HH:mm");
function HeaderApp({ isMenuOpen, menuToggle }: HeaderPropTypes) {
  const [currentDateTime, setCurrentDateTime] = useState(Date);
  const [activeButton, setActiveButton] = useState("delivery");
  const store = useStore();
  let pickupCount = useSelector((state: Storage) => state?.entities?.ordersSlice?.pickOrdersCount);
  let deliveryCount =  useSelector((state: Storage) => state?.entities?.ordersSlice?.deliveryOrdersCount);

  const handleDeliveryClick = () => {
    try {
      store.dispatch({
        type: "filterSlice/setFilter",
        payload: OrderType.DELIVERY,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const handlePickupClick = () => {
    try {
      store.dispatch({
        type: "filterSlice/setFilter",
        payload: OrderType.PICKUP,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment()?.format("dddd, D MMMM YYYY - HH:mm"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleToggleMenu = () => {
    menuToggle((prev: boolean) => !prev);
  };
  const [day, rest] = currentDateTime.split(", ");
  return (
    <div  className="header-app">
      <div className="right-section-app">
        <div className="logo-app">
          <img className="logo-img-app" src={logo} alt="logo" />
        </div>

        <div className="title-app">
          <img src={storeLogo} alt="store" />
          <span>Mon Magazin</span>
        </div>

        <button
          data-testid="menu-toggle-app"
          className="menu-toggle-app"
          onClick={handleToggleMenu}
        >
          {!isMenuOpen ? (
            <img src={menuHamburger} alt="hamburger" />
          ) : (
            <FontAwesomeIcon
              className="close-menu-icon-app"
              icon={icon({ name: "xmark" })}
            />
          )}
        </button>
      </div>
      <div className="button-group-app">
        <button
          className={
            activeButton === OrderType.DELIVERY ? "active-button-app" : ""
          }
          onClick={() => {
            setActiveButton(OrderType.DELIVERY);
            handleDeliveryClick();
          }}
        >
          Delivery ( {deliveryCount} )
        </button>
        <button
          className={
            activeButton === OrderType.PICKUP ? "active-button-app" : ""
          }
          onClick={() => {
            setActiveButton(OrderType.PICKUP);
            handlePickupClick();
          }}
        >
          Pickup ( {pickupCount} )
        </button>
      </div>
      <div className="left-section-app" data-testid="current-datetime">
        <span className="day-app">{day} , </span>
        <span className="rest-app">{rest}</span>
      </div>
    </div>
  );
}

export default HeaderApp;
