import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/auth";

interface PropsType {
  children: JSX.Element;
}

const Protected = ({ children }: PropsType) => {
  const {token , setToken} = useAuth()
  // const token = localStorage.getItem("token") || token;
    console.log('token =========>' , token);
    
  if (!token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default Protected;
