
import QRCode from "qrcode.react";
import PizzaIcon from "../../assets/pizzaIcon.svg";

function QRCodePrintComponent({ order }: any) {

  return (
    <QRCode
      id="qr-code-print"
      level="L"
      size={300}

      value={JSON.stringify(order.id)}
      autoFocus= {true}
      imageSettings={{
        src: PizzaIcon,
        height: 80,
        width: 80,

        excavate: true,
      }}
    />
  );
}


export default QRCodePrintComponent;
