import axios from "axios";
import { envconf } from "../utils/envConfig";
import jwt_decode from "jwt-decode";

export async function setActiveTrue(token : string) {
  console.log('token' , token);
  
  try {
    const decodedToken: any = Boolean(token) ?  jwt_decode(token) : null;
    const requestBody = { active: true };
    console.log("decodedToken 11" , decodedToken);
    
    const response = await axios.patch(
      `${envconf.SERVER_URL}/pizza-stores/${decodedToken?.store?.id}/toggle-active`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error setting active status to true:", error);
    throw error;
  }
}

export async function setActiveFalse() {
  try {
    const token = localStorage?.getItem("token") ?? "";
    const decodedToken: any = Boolean(token) ? jwt_decode(token) : null;
    const requestBody = { active: false };
    console.log("decoded" , decodedToken);
    
    const response = await axios.patch(
      `${envconf.SERVER_URL}/pizza-stores/${decodedToken?.store?._id}/toggle-active`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error setting active status to false:", error);
    throw error;
  }
}
