import { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import { envconf } from "../utils/envConfig";
import { safeJsonStringify } from "../utils/safeJsonStringfy";
import { setActiveFalse, setActiveTrue } from "./StoreActive";

interface AuthProviderPropsType {
  children: JSX.Element | JSX.Element[];
}

interface UserLoginPropsType {
  qrCode: string;
  appName: string;
}

interface InitialContextType {
  token: string;
  login: (
    user: UserLoginPropsType
  ) => Promise<{ success: boolean; msg: string }>;
  logout: () => void;
  getToken: () => string;
  setToken : (any) => void;
}

interface LoginResultType {
  success: boolean;
  token?: string;
  error?: string;
}

const initialContext: InitialContextType = {
  token: "",
  login: async (user: UserLoginPropsType) => {
    return { success: false, msg: "error" };
  },
  logout: () => {},
  getToken: () => {
    return "";
  },
  setToken : () => {}
};

const AuthContext = createContext(initialContext);

export const AuthProvider = ({ children }: AuthProviderPropsType) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken ? localToken : "");
  }, []);

  const login = async (user: UserLoginPropsType) => {
    const lgRes = await loginUser(user);
    console.log('lgRes' , lgRes);
    

    if (lgRes?.success) {
      localStorage?.setItem("token", lgRes.token ? lgRes.token : "");
      setToken(lgRes?.token ? lgRes.token : "");
      return { success: true, msg: "logged in" };
    } else if (lgRes?.error) {
      return { success: false, msg: lgRes.error };
    }
    return { success: false, msg: "error" };
  };

  const getToken = () => {
    const localToken = localStorage?.getItem("token") || "";

    if (!localToken) {
      return "";
    }

    return localToken;
  };

  const logout = () => {
    setActiveFalse();
    localStorage.removeItem("token");
    localStorage.clear();
    setToken("");
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, getToken , setToken}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

async function loginUser(user: UserLoginPropsType): Promise<LoginResultType> {
  try {
    return axios
      .post(`${envconf.SERVER_URL}/verify-qr-code`, user)
      .then((res) => {
        if (res.data?.status === 200 && res.data?.success) {
          setActiveTrue(res.data?.content?.token);
          return {
            success: true,
            token: res.data?.content?.token,
          };
        }
        return {
          success: false,
          error: res.data?.message,
        };
      })
      .catch((err) => {
        return {
          success: false,
          error: safeJsonStringify(err),
        };
      });
  } catch (error) {
    return {
      success: false,
      error: safeJsonStringify(error),
    };
  }
}
