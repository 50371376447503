import { OrderStatus, PizzaSizeEnum } from "../services/customTypes";

export function translateOrderStatus(status: string): string {
    switch (status) {
      case OrderStatus.READY:
        return "Prêt";
      case OrderStatus.DELIVERED:
        return "Livré";
      case OrderStatus.REJECTED:
        return "Rejeté";
      case OrderStatus.TAKEN:
        return "Pris";
      case OrderStatus.PICKED:
        return "récupérée";
      default:
        return "";
    }
  }

  export function translateMessage(message: string): string {
    switch (message) {
      case "Your Qr Is not valid":
        return "Votre QR n'est pas valide.";
      case "You dont have access to this app":
        return "Vous n'avez pas accès à cette application.";
      default:
        return "";
    }
  }


  export function translateSizeOrder(Size: string): string {
    switch (Size) {
      case PizzaSizeEnum.large:
        return "grand";
      case PizzaSizeEnum.medium:
        return "moyenne";
      case PizzaSizeEnum.small:
        return "Petit";
      default:
        return "";
    }
  }