export enum OrderStatus {
  NOTASSIGNED = "notAssigned",
  CANCELED = "canceled",
  ASSIGNED = "assigned",
  CONFIRMED = "confirmed",
  REJECTED = "rejected",
  READY = "ready",
  PICKED = "picked",
  DELIVERED = "delivered",
  NOTDELIVERED = "notdelivered",
  RETURNED = "returned",
  TAKEN = "taken",
}
export enum OrderType {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}
export interface ProductType {
  id: string;
  name: string;
  size: string;
  Price: number;
  quantity: number;
  toppings?: {
    toppingName: string;
    quantity: number;
  }[];

  extras?: {
    extra: {
      name: string;
      price: number;
      type: string;
      quantity: number;
    };
  }[];
}

export interface OrdersType {
  _id?: string;

  id: string;
  orderNumber: number;
  OrderType: string;
  Status: string;
  createdAt: string;
  Store?: {
    name: string;
    phoneNumber: string;
    publicKey: string;
    address: {
      name: string;
      latitude: number;
      longtitude: number;
    };
  };
  orderLine: {
    product: ProductType[];
    extras?: {
      extra: {
        name: string;
        price: number;
        type: string;
        quantity: number;
        unit: string;
        volume: number;
      };
    }[];
  }[];
}
export const emptyOrder: OrdersType = {
  id: "",
  orderNumber: 0,
  OrderType: "",
  Status: "",
  createdAt: "",
  orderLine: [
    {
      product: [
        {
          id: "",
          name: "",
          size: "",
          Price: 0,
          quantity: 0,
        },
      ],
      extras: [
        {
          extra: {
            name: "",
            price: 0,
            type: "",
            quantity: 0,
            unit:"",
            volume:0
          },
        },
      ],
    },
  ],
  Store: {
    name: "",
    phoneNumber: "",
    publicKey: "",
    address: {
      name: "",
      latitude: 0,
      longtitude: 0    
    },
  }
};


export interface tokenType {
  exp: number;
  iat: number;
  id: string;
  username: string;
  store:{
    name: string;
    phoneNumber: string;
  }
}

export interface OrderLineData {
  content: {
    orderLine: {
      id: string;
      orderNumber: string;
      product: ProductType[];
    };
    message: string;
    status: number;
    success: boolean;
  };
}

export interface HistoryOrdersData {
  orders: OrdersType[];
  totalPages: number;
  count: number;
}

export interface HistoryOrdersResponse {
  status: number;
  success: boolean;
  content: HistoryOrdersData;
}

export interface HistoryOrdersResult {
  success: boolean;
  data: HistoryOrdersResponse;
}

export interface OrdersState {
  pickOrdersCount: number;
  deliveryOrdersCount: number;
}


export enum PizzaSizeEnum {
  small = 'SMALL',
  medium = 'MEDIUM',
  large = 'LARGE',
}

export enum ExtraEnum {
  gram = 'gram',
  litre = 'litre',
  centiliter = 'centiliter',
  millilitre = "milliliter",
}