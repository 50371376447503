// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/login-background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat;
  background-size: contain;
  position: absolute;
  width: 100%; /* Adjust the width as needed */
}

.logo-section {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.logo-image {
  width: 100%;
  max-height: 134px;
}

.logo-text {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.form-section {
  margin-top: 5%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/login/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yDAAA;EACA,yBAAA;EACA,wBAAA;EACA,kBAAA;EACA,WAAA,EAAA,+BAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,qCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;AACF","sourcesContent":[".login-container {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  background-image: url(\"../../assets/login-background.svg\");\n  background-repeat: repeat;\n  background-size: contain;\n  position: absolute;\n  width: 100%; /* Adjust the width as needed */\n}\n\n.logo-section {\n  height: 40vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.logo-image {\n  width: 100%;\n  max-height: 134px;\n}\n\n.logo-text {\n  margin-top: 20px;\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 19px;\n}\n\n.form-section {\n  margin-top: 5%;\n  height: 60vh;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
