// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
  font-family: "Montserrat";
  font-style: normal;
  background-color: #ededed;
  min-height: 100vh;
}
.layout-container .content {
  width: 100%;
  padding: 30px;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/app-screen/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EAEA,yBAAA;EACA,iBAAA;AAAF;AACE;EACE,WAAA;EACA,aAAA;EACA,aAAA;AACJ","sourcesContent":[".layout-container {\n  font-family: \"Montserrat\";\n  font-style: normal;\n\n  background-color: #ededed;\n  min-height: 100vh;\n  .content {\n    width: 100%;\n    padding: 30px;\n    height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
