import { OrderType, OrdersType, ProductType } from "../services/customTypes";
import { getAbbreviation } from "./extraEnumConvert";

export function getToppingsString(order:ProductType) {
    return order.toppings?.map(topping => `${topping.quantity} x ${topping.toppingName}`).join(',  ');
}

export function getExtrasString(order:any) {
    return order.orderLine?.[0]?.extras
      ? order.orderLine[0].extras.map((extra : any ) => `${extra?.quantity ?? ""} x ${extra?.name ?? ""} ${extra?.extra?.volume ?? ""}${getAbbreviation(extra?.extra?.unit) ?? ""}`).join(', ')
      : '';
  }
  