import { OrderType } from "../../../services/customTypes";

const ordersSlice = {
  initialState: {
    pickOrdersCount: 0,
    deliveryOrdersCount: 0,
    ordersPickup: [],
    ordersDelivery: []
  },
  reducers: {
    setOrders: (state: any, action: any) => {
      const { pickupOrders, deliveryOrders } = action.payload;
      state.pickOrdersCount = pickupOrders.length;
      state.deliveryOrdersCount = deliveryOrders.length;
      state.ordersPickup = pickupOrders;
      state.ordersDelivery = deliveryOrders;
    },

    addOrderPickup: (state: any, action: any) => {
      state.ordersPickup.push(action.payload);
      state.pickOrdersCount += 1;
    },

    deleteOrderPickup: (state: any, action: any) => {
      const index = state.ordersPickup.findIndex(
        (order: any) => order.id === action.payload.id
      );
      if (index !== -1) {
        state.ordersPickup.splice(index, 1);
        state.pickOrdersCount -= 1;
      }
    },

    updateOrderPickup: (state: any, action: any) => {
      const index = state.ordersPickup.findIndex(
        (order: any) => order.order.id === action.payload.order.id
      );
      if (index !== -1) {
        state.ordersPickup[index] = {
          ...state.ordersPickup[index],
          ...action.payload
        };
      }
    },

    addOrderDelivery: (state: any, action: any) => {
      state.ordersDelivery.push(action.payload);
      state.deliveryOrdersCount += 1;
    },

    deleteOrderDelivery: (state: any, action: any) => {
      const index = state.ordersDelivery.findIndex(
        (order: any) => order.order.id === action.payload.order.id
      );
      if (index !== -1) {
        state.ordersDelivery.splice(index, 1);
        state.deliveryOrdersCount -= 1;
      }
    },

    updateOrderDelivery: (state: any, action: any) => {
      const index = state.ordersDelivery.findIndex(
        (order: any) => order.order.id === action.payload.order.id
      );
      if (index !== -1) {
        state.ordersDelivery[index] = {
          ...state.ordersDelivery[index],
          ...action.payload
        };
      }
    },
    processOrders: (state: any, action: any) => {
      const orders = action.payload;
      console.log("xxxxxxx" ,orders);
      

      orders.orders.forEach((order: any) => {
        console.log("iiiii" , order);
        
        if (order.OrderType === OrderType.PICKUP) {
          console.log("00");
          
          const index = state.ordersPickup.findIndex((o: any) => o.id == order.id);
          console.log("index" , index);
          
          if (index !== -1) {
            // Update existing pickup order
            state.ordersPickup[index] = { ...state.ordersPickup[index], ...order };
          } else {
            console.log("222222");
            
            // Add new pickup order
            
            state.ordersPickup.push(order);
            state.pickOrdersCount += 1;
          }
        } else if (order.OrderType === OrderType.DELIVERY) {
          console.log("11");

          const index = state.ordersDelivery.findIndex((o: any) => o.id === order.id);
          if (index !== -1) {
            // Update existing delivery order
            state.ordersDelivery[index] = { ...state.ordersDelivery[index], ...order };
          } else {
            // Add new delivery order
            state.ordersDelivery.push(order);
            state.deliveryOrdersCount += 1;
          }
        }
      });
    },

  }
};

export default ordersSlice;
