import "./index.scss";
import {
  OrdersType,
  OrderStatus,
  OrderType,
} from "../../../services/customTypes";
import { updateOrderStatus } from "../../../services/orders";
import {
  getExtrasString,
  getToppingsString,
} from "../../../utils/getToppingName";
import { useEffect, useRef, useState } from "react";
import close from "../../../assets/close.svg";
import { useReactToPrint } from "react-to-print";
import QRCodePrintComponent from "../../QRcode-Print";
interface OrderCardPropsType {
  order: OrdersType;
  onClickCard: Function;
  onOrderStatusUpdate: Function;
}

function OrderCard({
  order,
  onClickCard,
  onOrderStatusUpdate,
}: OrderCardPropsType) {
  const [confirming, setConfirming] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const confirmationRef = useRef(null);
  const printComponentRef = useRef(null);

  const handleStartOrder = async () => {
    setConfirming(true);
    setNewStatus(OrderStatus.CONFIRMED);
  };

  const handleOrderDone = async () => {
    setConfirming(true);
    setNewStatus(OrderStatus.READY);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handleOrderTaken = async () => {
    setConfirming(true);
    setNewStatus(OrderStatus.TAKEN);
  };

  function stopSound() {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage("stopSound");
    } else {
      console.error("ReactNativeWebView is not available");
    }
  }

  const handleConfirm = async () => {
    stopSound()
    await updateOrderStatus(order.id, newStatus);
    onOrderStatusUpdate({ ...order, Status: newStatus });
    setConfirming(false);
  };

  const handleCancel = () => {
    setConfirming(false);
    setNewStatus("");
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        confirmationRef.current &&
        !(confirmationRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setConfirming(false);
        setNewStatus("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (confirming && newStatus === OrderStatus.READY) {
      handlePrint();
    }
  }, [confirming, newStatus]);

  let statusButton = null;
  if (order.OrderType === OrderType.PICKUP) {
    if (order.Status === OrderStatus.NOTASSIGNED) {
      statusButton = (
        <button
          className="btn-change-status primary"
          onClick={handleStartOrder}
        >
          <span>Confirmer</span>
        </button>
      );
    } else if (order.Status === OrderStatus.CONFIRMED) {
      statusButton = (
        <button
          className="btn-change-status secondary"
          onClick={handleOrderDone}
        >
          <span>Prête</span>
        </button>
      );
    } else if (order.Status === OrderStatus.READY) {
      statusButton = (
        <button
          className="btn-change-status tertiary"
          onClick={handleOrderTaken}
        >
          <span>Pris</span>
        </button>
      );
    }
  } else if (order.OrderType === "delivery") {
    if (order.Status === OrderStatus.ASSIGNED) {
      statusButton = (
        <button
          className="btn-change-status primary"
          onClick={handleStartOrder}
        >
          <span>Commencer</span>
        </button>
      );
    } else if (order.Status === OrderStatus.CONFIRMED) {
      statusButton = (
        <button
          className="btn-change-status secondary"
          onClick={handleOrderDone}
        >
          <span>Prête</span>
        </button>
      );
    }
  }
  const extraNames = getExtrasString(order);
  return (
    <div id="orderCard" className="order-card">
      <div className="QrcodeBody">
        <div className="QrCodeContainer" ref={printComponentRef}>
          <QRCodePrintComponent order={order} />
        </div>
      </div>
      {!confirming ? (
        <div
          className={`row order-details ${
            order.Status === OrderStatus.ASSIGNED ||
            order.Status === OrderStatus.NOTASSIGNED
              ? ""
              : order.OrderType === OrderType.PICKUP &&
                order.Status === OrderStatus.READY
              ? "bg-ready"
              : "bg-yellow"
          }`}
        >
          <section
            className="click-section"
            onClick={(e) => {
              e.preventDefault();
              onClickCard();
            }}
          >
            <div className="order-header">
              <div className="order-num">
                Commande n° <span>{order.orderNumber}</span>
              </div>
              <div className="order-status">
                {order.Status === OrderStatus.ASSIGNED ||
                order.Status === OrderStatus.NOTASSIGNED
                  ? "Non confirmé"
                  : order.Status === OrderStatus.CONFIRMED
                  ? "Confirmé"
                  : "Prêt"}
              </div>
            </div>
            {order.OrderType !== OrderType.DELIVERY ? (
              <div className="order-type">Pickup</div>
            ) : (
              <div className="order-type green">Delivery</div>
            )}
            <div className="order-items">
              {order?.orderLine?.[0]?.product?.map((orderItem, index) => {
                const toppingNames = getToppingsString(orderItem);

                return (
                  <div key={index} className="item">
                    <div className="title">
                      {orderItem?.quantity ?? 1} x{" "}
                      {orderItem?.name?.replace("PZZ_", "Pizza ")}
                    </div>
                    <div className="item-details">
                      <span>topping</span> {toppingNames}
                    </div>
                  </div>
                );
              })}
            </div>
            {extraNames && extraNames.length > 0 && (
              <div className="extras-section">
                <span className="extra-title">Extras </span> {extraNames}
              </div>
            )}
          </section>

          <div className="order-footer">{statusButton}</div>
        </div>
      ) : (
        <div ref={confirmationRef} className="confirmation-content">
          <div className="order-num-confirmation">
            Commande n° <span>{order.orderNumber}</span>
            <button
              className="btn-cancel"
              onClick={handleCancel}
              data-testid="button-X"
            >
              <img className="close-img" src={close} alt="logo" />
            </button>
          </div>

          <div className="confirm-message">
            {order.Status === OrderStatus.ASSIGNED ||
            order.Status === OrderStatus.NOTASSIGNED
              ? "Vous allez commencer la préparation  ?"
              : "Êtes-vous sûr de vouloir continuer ?"}
          </div>
          <div className="Confiramtion-buttons">
            <button className="btn-confirm" onClick={handleConfirm}>
              Confirmer
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderCard;
