import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import {
  OrderStatus,
  OrderType,
  OrdersType,
} from "../../../services/customTypes";
import { updateOrderStatus } from "../../../services/orders";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Lottie from "lottie-react";
import loading from "../../../assets/loading.json";
import loadImage from "../../../assets/lottie-animations/loading.json";
import { getPhoto } from "../../../services/photos";
import { getExtrasString } from "../../../utils/getToppingName";
import { translateSizeOrder } from "../../../utils/translations";
import { useReactToPrint } from "react-to-print";
import QRCodePrintComponent from "../../QRcode-Print";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
import { postRefaire } from "../../../services/post-refaire";
import { showSwalNotification } from "../../../utils/swalUtils";

interface PropsType {
  order: OrdersType;
  closeOrderClick?: Function;
  onOrderStatusUpdate: Function;
  stopSound : Function;
}

const OrderDetails = ({
  order,
  closeOrderClick,
  onOrderStatusUpdate,
  stopSound
}: any) => {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [printed, setPrinted] = useState(false);
  const printComponentRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingRefaire, setLoadingRefaire] = useState(false);
  const [isRefaire, setIsRefaire] = useState(false);
  const [orderRefaire, setOrderRefaire] = useState<any>(null);
  const [refaireCount, setRefaireCount] = useState<number | null>(null);

  const handlecloseOrderClick = () => {
    closeOrderClick instanceof Function && closeOrderClick(false);
  };

  const handleStartOrder = async () => {
    stopSound()   
 await updateOrderStatus(order.id, OrderStatus.CONFIRMED);
    onOrderStatusUpdate({ ...order, Status: OrderStatus.CONFIRMED });
    setStartTime(Date.now());
   
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handleOrderDone = async () => {
    await updateOrderStatus(order.id, OrderStatus.READY);
    localStorage.removeItem(`order-${order.id}-startTime`);
    localStorage.removeItem(`order-${order.id}-elapsedTime`);
    onOrderStatusUpdate({ ...order, Status: OrderStatus.READY });
    closeOrderClick instanceof Function && closeOrderClick(false);
    handlePrint()
    stopSound()

 

  };

  const handleOrderDonePickup = async () => {
    await updateOrderStatus(order.id, OrderStatus.READY);
    onOrderStatusUpdate({ ...order, Status: OrderStatus.READY });
    localStorage.removeItem(`order-${order.id}-startTime`);
    localStorage.removeItem(`order-${order.id}-elapsedTime`);
    handlePrint()
    stopSound()


  };

  const handleRejectOrder = async () => {
    await updateOrderStatus(order.id, OrderStatus.REJECTED);
    onOrderStatusUpdate({ ...order, Status: OrderStatus.REJECTED });
    closeOrderClick instanceof Function && closeOrderClick(false);
    stopSound()

  };

  const handlePickupOrder = async () => {
    await updateOrderStatus(order.id, OrderStatus.TAKEN);
    onOrderStatusUpdate({ ...order, Status: OrderStatus.TAKEN });
    closeOrderClick instanceof Function && closeOrderClick(false);
    stopSound()

  };

  const [pizzaPhotos, setPizzaPhotos] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (order.Status === OrderStatus.CONFIRMED) {
      setStartTime(Date.now());
    }
  }, [order.Status]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (startTime && order.Status === OrderStatus.CONFIRMED) {
      interval = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [startTime, order.Status]);

  useEffect(() => {
    const storedStartTime = localStorage.getItem(`order-${order.id}-startTime`);
    const storedElapsedTime = localStorage.getItem(
      `order-${order.id}-elapsedTime`
    );
    if (storedStartTime && storedElapsedTime) {
      setStartTime(parseInt(storedStartTime));
      setElapsedTime(parseInt(storedElapsedTime));
    }
  }, [order.id]);

  useEffect(() => {
    if (startTime) {
      localStorage.setItem(`order-${order.id}-startTime`, startTime.toString());
      localStorage.setItem(
        `order-${order.id}-elapsedTime`,
        elapsedTime.toString()
      );
    }
  }, [order.id, startTime, elapsedTime]);

  useEffect(() => {
    const fetchPizzaPhotos = async () => {
      order.orderLine[0].product.forEach(async (orderItem) => {
        let photoUrl = "";
        if (orderItem.name === "PZZ_Custum") {
          photoUrl = (await getPhoto("PZZ_Start")) ?? "";
        } else {
          photoUrl = (await getPhoto(orderItem.name)) ?? "";
        }
        if (photoUrl) {
          setPizzaPhotos((prevPhotos) => ({
            ...prevPhotos,
            [orderItem.name]: photoUrl,
          }));
        }
      });
    };

    fetchPizzaPhotos();
  }, [order]);

  useEffect(() => {
    if (order.Status === OrderStatus.CONFIRMED && !printed) {
      setPrinted(true);
    }
  }, [order.Status, handlePrint, printed]);

  const handleLoadEnd = () => {
    setLoadingImage(false);
  };

  const doRefaire = (orderItem: any) => {
    setIsRefaire(true);
    setOrderRefaire(orderItem);
    setRefaireCount(1);
  };

  const handleRefaireCountChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRefaireCount(Number(event.target.value));
  };

  const handleRefaireCancel = () => {
    setIsRefaire(false);
    setOrderRefaire(null);
    setRefaireCount(null);
  };

  const handleRefaireConfirm = () => {
    // Logic to handle the confirmation of refaire
    console.log(
      `Remaking ${refaireCount} pizzas of type ${orderRefaire?.name}`
    );
    doRefaireAction();
    setIsRefaire(false);
    setOrderRefaire(null);
    setRefaireCount(null);
  };

  const doRefaireAction = () => {
    setLoadingRefaire(true);
    const orderl = { ...orderRefaire };
    orderl.product = [orderRefaire];
    const body = {
      orderLine: orderl,
      storeID: order?.Store?.phoneNumber,
    };
    postRefaire(body)
      .then((response: any) => {
        setLoadingRefaire(false);

        if (response?.success) {
          showSwalNotification({
            html: "Commande a été Refairer avec succès !",
            customClass: {
              htmlContainer: "html-text",
              timerProgressBar: "bg-QRcustom-Success",
            },
          });
        } else {
          showSwalNotification({
            html: `fail : ${response?.message}`,
            customClass: {
              htmlContainer: "html-text",
              timerProgressBar: "bg-QRcustom-Success",
            },
          });
        }
      })
      .catch((error) => {
        setLoadingRefaire(false);
        showSwalNotification({
          html: `fail ${error} !`,
          customClass: {
            htmlContainer: "html-text",
            timerProgressBar: "bg-QRcustom-Success",
          },
        });
      });
  };

  const extraNames = getExtrasString(order);

  return (
    <div id="OrderDetails" className="lst-order-details">
      <div className="QrcodeBody">
        <div className="QrCodeContainer" ref={printComponentRef}>
          <QRCodePrintComponent order={order} />
        </div>
      </div>
      <header>
        <div className="info">
          Commande n° <span className="order-number">{order.orderNumber}</span>
          {order.OrderType !== "delivery" ? (
            <div className="order-type">Pickup</div>
          ) : (
            <div className="order-type green">Delivery</div>
          )}
        </div>
        <div className="Btn-x">
          {closeOrderClick instanceof Function && (
            <button className="close-button" onClick={handlecloseOrderClick}>
              <FontAwesomeIcon icon={icon({ name: "xmark" })} />
            </button>
          )}
        </div>
      </header>
      <main>
        {order.orderLine[0].product.map((orderItem, index) => {
          if (isRefaire && orderRefaire?.id === orderItem?.id) {
            return (
              <>
                {loadingRefaire ? (
                  <div>
                    <Lottie animationData={loadImage} loop={true} />
                  </div>
                ) : (
                  <div className="refaire-container" key={index}>
                    <span className="pzz-refaire-title">
                      {orderRefaire?.name}
                    </span>
                    <span className="pzz-refaire-msg">
                      Combien de Pizzas vous allez refaire ?
                    </span>
                    <div className="drop-down-refaire-count-container">
                      <select
                        className="select-count"
                        value={refaireCount ?? 1}
                        onChange={handleRefaireCountChange}
                      >
                        {Array.from(
                          { length: orderRefaire?.quantity ?? 1 },
                          (_, i) => i + 1
                        ).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="btn-refaire-container">
                      <button
                        className="btn-cancel-refaire"
                        onClick={handleRefaireCancel}
                      >
                        Annuler
                      </button>
                      <button
                        className="btn-confirm-refaire"
                        onClick={handleRefaireConfirm}
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                )}
              </>
            );
          } else {
            return (
              <div key={index} className="order-item">
                {loadingImage && (
                  <div className="lottie-wrapper">
                    <Lottie animationData={loadImage} loop={true} />
                  </div>
                )}
                {orderItem?.name == "PZZ_Custum" ? (
                  <img
                    src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=PZZ_Start`}
                    alt="item"
                    style={{ width: "175px", height: "200px" }}
                    onLoad={handleLoadEnd}
                  />
                ) : (
                  <img
                    src={`${process.env?.REACT_APP_SERVER_URL}/photo?pizza=${orderItem?.name}`}
                    alt="item"
                    style={{ width: "175px", height: "200px" }}
                    onLoad={handleLoadEnd}
                  />
                )}

                <div className="item-details">
                  <div className="title-container">
                    <div className="title">
                      {orderItem?.quantity ?? 1} x{" "}
                      {orderItem?.name?.replace("PZZ_", "Pizza ")}
                    </div>
                    <div>
                      <button
                        className="btn-redo"
                        onClick={() => doRefaire(orderItem)}
                      >
                        Refaire
                      </button>
                    </div>
                  </div>
                  <div className="size">
                    {translateSizeOrder(orderItem?.size?.toUpperCase())}
                  </div>
                  <div className="topping-list">
                    <span>Topping</span>
                    <div className="topping-items">
                      {orderItem.toppings?.map((topping, index) => (
                        <div
                          className={`topping-item ${
                            order.Status === OrderStatus.CONFIRMED
                              ? "topping-item-confirmed"
                              : ""
                          }`}
                          key={index}
                        >
                          {`${topping.quantity} x ${topping.toppingName}`}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
        {extraNames && extraNames.length > 0 && (
          <div className="extras-section">
            <span className="extra-title">Extras </span> {extraNames}
          </div>
        )}
      </main>
      <footer>
        {order.Status === OrderStatus.CONFIRMED && (
          <div className="elapsed-time">
            <div className="lottie-wrapper">
              <Lottie animationData={loading} loop={true} />
            </div>
            <div className="time-wrappers">
              {moment.utc(elapsedTime * 1000).format("HH:mm:ss")}
            </div>
          </div>
        )}
        <div className={`actionsReady full-width`}>
          {order.Status === OrderStatus.READY ? (
            <button className="btn-accept" onClick={handlePickupOrder}>
              <span>Pris</span>
            </button>
          ) : (order.Status === OrderStatus.CONFIRMED &&
              order.OrderType === OrderType.DELIVERY) ||
            (order.Status === OrderStatus.CONFIRMED &&
              order.OrderType === OrderType.PICKUP) ? (
            <button
              className="btn-accept"
              onClick={() => {
                order.OrderType === OrderType.PICKUP
                  ? handleOrderDonePickup()
                  : handleOrderDone();
              }}
            >
              <span>Prête</span>
            </button>
          ) : (
            <div className={`actions full-width`}>
              <button className="btn-reject" onClick={handleRejectOrder}>
                <span>Refuser</span>
              </button>
              <button className="btn-accept" onClick={handleStartOrder}>
                <span>Commencer</span>
              </button>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
};

export default OrderDetails;
