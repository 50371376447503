import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/login";
import ApplicationLayout from "./components/layouts/app-screen";
import WelcomeLayout from "./components/layouts/welcome-screen";
import { AuthProvider } from "./services/auth";
import Protected from "./components/protected-route";
import TokenExpirationChecker from "./services/tokenExpiration";
import React from "react";
import socket from "./services/socket";
import {
  onSocketConnect,
  onSocketConnectionError,
  onSocketDisconnect,
} from "./services/socketEvents";

function App() {
  const [token, setToken] = React.useState("");
  React.useEffect(() => {
    socket.connect();
    onSocketConnect();
    onSocketConnectionError();
    onSocketDisconnect();
    return () => {
      socket.disconnect();
    };
  }, []);

  React.useEffect(() => {

  }, []);

  return (
    <AuthProvider>
      <TokenExpirationChecker />
      <Routes>
        <Route
          path="/"
          element={
            // <Protected token={token}>
            <WelcomeLayout />
            // </Protected>
          }
        />
        <Route
          path="app"
          element={
            <Protected>
              <ApplicationLayout />
            </Protected>
          }
        />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
