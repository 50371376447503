import { useState, useEffect, useCallback } from "react";
import Masonry from "react-masonry-css";
import "./index.scss";
import OrderCard from "./order-card";
import OrderDetails from "./order-details";
import loader from "../../assets/loader.json";

import {
  OrderStatus,
  OrderType,
  OrdersType,
  emptyOrder,
} from "../../services/customTypes";
import { getAllOrders, getOrderLine } from "../../services/orders";
import Modal from "../modal";
import socket from "../../services/socket";
import { useSelector, useStore } from "react-redux";
import jwt_decode from "jwt-decode";
import { showSwalNotification } from "../../utils/swalUtils";
import Lottie from "lottie-react";
import empty from "../../assets/empty.json";
import CallButton from "../call-Button";
import orderInsertedSound from "./orderInserted.mp3";
import orderUpdated from "./orderUpdated.mp3";
import Reload from "./../../assets/reload.svg";

function OrderList() {
  const [selectedOrder, setSelectedOrder] = useState(emptyOrder);
  const [orderList, setOrderList] = useState<OrdersType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const store = useStore();
  const filter = useSelector((state: Storage) => state?.entities?.filterSlice);
  const token = localStorage.getItem("token") ?? "";
  const decodedToken: any = token ? jwt_decode(token) : null;


  const handleCardClick = (order: OrdersType) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const PickupOrders = useSelector(
    (state: Storage) => state?.entities?.storeOrderSlice?.ordersPickup
  );
  const DeliveryOrders = useSelector(
    (state: Storage) => state?.entities?.storeOrderSlice?.ordersDelivery
  );

  const getOrderUpdateWithSocket = () => {
    socket.on("orderUpdate", (order: any) => {
      if (order?.OrderType == OrderType.PICKUP) {
        if (
          order.Status === OrderStatus.NOTASSIGNED ||
          order.Status === OrderStatus.CONFIRMED ||
          order.Status === OrderStatus.READY ||
          order.Status === OrderStatus.TAKEN ||
          order.Status === OrderStatus.REJECTED ||
          order.Status === OrderStatus.RETURNED ||
          order.Status === OrderStatus.CANCELED
        ) {
          const audio = new Audio(orderInsertedSound);

          const playAudio = () => {
            audio.play().catch((error) => {
              console.error("Error playing audio:", error); // Handle errors such as interruptions
            });
          };

          const intervalId = setInterval(() => {
            // Play the sound every 2 seconds
            if (audio.paused) {
              // Ensure audio isn't already playing
              playAudio();
            }
          }, 2000);

          // Stop repeating after 20 seconds
          setTimeout(() => {
            clearInterval(intervalId); // Stop repeating
            audio.pause(); // Stop playing audio if it's still playing
            audio.currentTime = 0; // Reset the audio to the beginning
          }, 20000);
          try {
            store.dispatch({
              type: "storeOrderSlice/updateOrderPickup",
              payload: { order },
            });
          } catch (error) {
            console.log("errror redux");
          }
        }
      } else {
        if (
          order.Status === OrderStatus.ASSIGNED ||
          order.Status === OrderStatus.CONFIRMED ||
          order.Status === OrderStatus.READY ||
          order.Status === OrderStatus.PICKED ||
          order.Status === OrderStatus.REJECTED ||
          order.Status === OrderStatus.CANCELED ||
          order.Status === OrderStatus.NOTASSIGNED
        ) {
          const audio = new Audio(orderInsertedSound);

          const playAudio = () => {
            audio.play().catch((error) => {
              console.error("Error playing audio:", error); // Handle errors such as interruptions
            });
          };

          const intervalId = setInterval(() => {
            // Play the sound every 2 seconds
            if (audio.paused) {
              // Ensure audio isn't already playing
              playAudio();
            }
          }, 2000);

          // Stop repeating after 20 seconds
          setTimeout(() => {
            clearInterval(intervalId); // Stop repeating
            audio.pause(); // Stop playing audio if it's still playing
            audio.currentTime = 0; // Reset the audio to the beginning
          }, 20000);
          try {
            store.dispatch({
              type: "storeOrderSlice/updateOrderDelivery",
              payload: { order },
            });
          } catch (error) {
            console.log("errror redux");
          }
        }
      }
    });
  };
  const getOrderInsertWithSocket = () => {
    socket.on("orderInsert", (order: any) => {
      if (order?.OrderType == OrderType.PICKUP) {
        if (order.Status === OrderStatus.NOTASSIGNED) {
          const audio = new Audio(orderInsertedSound);

          const playAudio = () => {
            audio.play().catch((error) => {
              console.error("Error playing audio:", error); // Handle errors such as interruptions
            });
          };

          const intervalId = setInterval(() => {
            // Play the sound every 2 seconds
            if (audio.paused) {
              // Ensure audio isn't already playing
              playAudio();
            }
          }, 2000);

          // Stop repeating after 20 seconds
          setTimeout(() => {
            clearInterval(intervalId); // Stop repeating
            audio.pause(); // Stop playing audio if it's still playing
            audio.currentTime = 0; // Reset the audio to the beginning
          }, 20000);
          try {
            store.dispatch({
              type: "storeOrderSlice/addOrderPickup",
              payload: { ...order },
            });
          } catch (error) {
            console.log("errror redux");
          }
        }
      } else {
        if (order.Status === OrderStatus.ASSIGNED) {
          const audio = new Audio(orderInsertedSound);

          const playAudio = () => {
            audio.play().catch((error) => {
              console.error("Error playing audio:", error); // Handle errors such as interruptions
            });
          };

          const intervalId = setInterval(() => {
            // Play the sound every 2 seconds
            if (audio.paused) {
              // Ensure audio isn't already playing
              playAudio();
            }
          }, 2000);

          // Stop repeating after 20 seconds
          setTimeout(() => {
            clearInterval(intervalId); // Stop repeating
            audio.pause(); // Stop playing audio if it's still playing
            audio.currentTime = 0; // Reset the audio to the beginning
          }, 20000);
          try {
            store.dispatch({
              type: "storeOrderSlice/addOrderDelivery",
              payload: { order },
            });
          } catch (error) {
            console.log("errror redux");
          }
        }
      }
    });
  };

  const doGetAllOrders = () => {
    getAllOrders().then((response: any) => {
      if (response?.success) {
        const orders = response?.data;
        
        try {
          store.dispatch({
            type: "storeOrderSlice/processOrders",
            payload: { orders : orders , phoneStore : decodedToken?.phoneNumber },
          });
        } catch (error) {
          console.log("errror redux");
        }
      } else {
        showSwalNotification({
          html: `${response?.message}`,
          customClass: {
            htmlContainer: "html-text",
            timerProgressBar: "bg-QRcustom-Success",
          },
        });
      }
    });
  };

  useEffect(() => {
    doGetAllOrders();
    getOrderUpdateWithSocket();
    getOrderInsertWithSocket();
    return () => {
      socket.off("orderUpdate");
      socket.off("orderInsert");
    };
  }, []);

  const filteredOrders = orderList.filter((order) =>
    filter === "all" ? true : order.OrderType === filter
  );
  const doReload = () => {
    console.log("tttttttttt");
    
    doGetAllOrders();
  };

  return (
    <div id="OrderList" className="order-list" data-testid="order-list">
      <div className="reload-container" onClick={() => doReload()}>
        <img src={Reload} />
      </div>
      {PickupOrders.length > 0 && filter == "pickup" ? (
        <Masonry
          breakpointCols={{ default: 4, 1400: 3, 1200: 2, 800: 1 }}
          className="card-list"
          columnClassName="my-masonry-grid_column"
        >
          {PickupOrders.map((order: OrdersType) => (
            <OrderCard
              key={order.id}
              order={order}
              onClickCard={() => handleCardClick(order)}
              onOrderStatusUpdate={() => {}}
            />
          ))}
        </Masonry>
      ) : DeliveryOrders.length > 0 && filter == "delivery" ? (
        <Masonry
          breakpointCols={{ default: 4, 1400: 3, 1200: 2, 800: 1 }}
          className="card-list"
          columnClassName="my-masonry-grid_column"
        >
          {DeliveryOrders.map((order: OrdersType) => (
            <OrderCard
              key={order.id}
              order={order}
              onClickCard={() => handleCardClick(order)}
              onOrderStatusUpdate={() => {}}
            />
          ))}
        </Masonry>
      ) : !isLoading ? (
        <div className="lottie-wrapper">
          <Lottie className="lottie-animation" animationData={empty} loop />
        </div>
      ) : null}

      {isLoading && (
        <div className="loader">
          <Lottie animationData={loader} loop autoplay />
        </div>
      )}

      {modalOpen && (
        <Modal
          isConfirmed={selectedOrder?.Status === OrderStatus.CONFIRMED}
          isReady={selectedOrder?.Status === OrderStatus.READY}
          onClose={() => setModalOpen(false)}
        >
          <OrderDetails
            order={selectedOrder}
            closeOrderClick={setModalOpen}
            onOrderStatusUpdate={() => {}}
          />
        </Modal>
      )}

      <CallButton />
    </div>
  );
}

export default OrderList;
