import { ExtraEnum } from "../services/customTypes";

  
  export function getAbbreviation(input: string): string | undefined {
    switch (input) {
      case ExtraEnum.gram:
        return 'gr';
      case ExtraEnum.litre:
        return 'L';
      case ExtraEnum.centiliter:
        return 'cl';
      default:
        return ""; 
    }
  }
  