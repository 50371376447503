// weather.js
import axios from 'axios';
import { envconf } from '../utils/envConfig';
const API_URL = `https://api.openweathermap.org/data/2.5/weather?q=Tunis&appid=${envconf.API_KEY}&units=metric&lang=fr`;


export const fetchWeather = async () => {
  try {
    const response = await axios.get(API_URL);
    const { data } = response;
    const temperature = Math.round(data.main.temp);
    const icon = data.weather?.[0]?.icon
    return { temperature , icon };
  } catch (error) {
    console.log(error);
    return null;
  }
};
