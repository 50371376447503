import React, { useState, useEffect } from "react";
import logo from "../../../../assets/sidebar/logo-small.svg";
import profileImg from "../../../../assets/profile.svg";
import { fetchWeather } from "../../../../services/weather";
import moment from "moment";
import "moment/locale/fr";

import "./index.scss";

import jwt_decode from "jwt-decode";

function Header() {
  const date = moment().locale("fr").format("dddd, DD MMMM YYYY - HH:mm");
  const [weather, setWeather] = useState<{
    temperature: number;
    icon: string;
  } | null>(null);
  const [day, rest] = date.split(", ");

  const token = localStorage?.getItem("token") ?? "";
  const decodedToken: any = Boolean(token) ?  jwt_decode(token) : null;
  useEffect(() => {
    const getWeather = async () => {
      const data = await fetchWeather();
      setWeather(data);
    };
    getWeather();
  }, []);

  return (
    <div id="Header" className="header">
      <div className="logo-section">
        <img className="logo-img" src={logo} alt="logo" data-testid="logo" />
      </div>
      <div className="info-section">
        <>
          <div className="day" data-testid="day">
            {day} ,{" "}
          </div>
          <div className="rest" data-testid="date">
            {rest}
          </div>
          <img
            src={`http://openweathermap.org/img/wn/${weather?.icon ?? ""}.png`}
            alt="weather-icon"
            data-testid="weather-icon"
          />
          <span className="weather-value" data-testid="weather-temperature">
            {weather?.temperature ?? ""}°C
          </span>
        </>
      </div>
      <div className="user-section">
        <img
          className="profile-img"
          src={profileImg}
          alt="profile"
          data-testid="profile-img"
        />
        <span className="username" data-testid="username">
          {decodedToken?.username ? decodedToken?.username : ""}
        </span>
      </div>
    </div>
  );
}

export default Header;
