// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/my-store.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-list-container .wlc-msg {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.application-list-container .application-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;
  max-width: 990px;
}
.application-list-container .application-list .app-box {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #32a68a;
  width: 199px;
  height: 162px;
  border-radius: 9px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.application-list-container .application-list .app-box .logo {
  height: 50px;
  margin-bottom: 20px;
}
.application-list-container .application-list .app-box span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.application-list-container .application-list .empty-app-box {
  background-color: #b1acac;
  width: 199px;
  height: 162px;
  border-radius: 9px;
  margin-top: 40px;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/application-list/index.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,uBAAA;EACA,4BAAA;EACA,gBAAA;AADJ;AAGI;EACE,yDAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAFN;AAIM;EACE,YAAA;EACA,mBAAA;AAFR;AAKM;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAHR;AAOI;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AALN","sourcesContent":[".application-list-container {\n  .wlc-msg {\n    font-family: \"Montserrat\";\n    font-style: normal;\n    font-weight: 700;\n    font-size: 32px;\n    line-height: 39px;\n  }\n\n  .application-list {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: flex-start;\n    align-content: space-between;\n    max-width: 990px;\n\n    .app-box {\n      background-image: url(../../assets/my-store.svg);\n      background-color: #32a68a;\n      width: 199px;\n      height: 162px;\n      border-radius: 9px;\n      margin-top: 40px;\n\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      color: #ffffff;\n      text-align: center;\n      cursor: pointer;\n\n      .logo {\n        height: 50px;\n        margin-bottom: 20px;\n      }\n\n      span {\n        font-family: \"Montserrat\";\n        font-style: normal;\n        font-weight: 700;\n        font-size: 20px;\n        line-height: 24px;\n      }\n    }\n\n    .empty-app-box {\n      background-color: #b1acac;\n      width: 199px;\n      height: 162px;\n      border-radius: 9px;\n      margin-top: 40px;\n      cursor: default;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
