import axios from "axios"
import { envconf } from "../utils/envConfig";

export async function getPhoto(pizzaName : string) {
    try {
      const response = await axios.get(`${envconf.SERVER_URL}/photo`, {
        params: {
          pizza: pizzaName
        },
        responseType: 'arraybuffer' // To receive binary data as an array buffer
      });
      if (response.status === 200) {
        // Set the content type and create a Blob from the response data
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        // Create a URL for the blob and return it
        return URL.createObjectURL(blob);
      } else {
        // Handle other status codes here
        console.error('Failed to retrieve photo:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error retrieving photo:', error);
      return null;
    }
  }
  