import "./index.scss";
import logo from "../../assets/logo-large.svg";
import LoginForm from "./form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LoginPage() {

  
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  useEffect(() => {
    if(token){
      navigate("/app")
    }

  } , [token])

  return (
    <div className="login-container">
      <div className="logo-section">
        <img className="logo-image" src={logo} alt="logo" />
      </div>

      <div className="form-section">
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginPage;
