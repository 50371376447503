import { OrderStatus, OrderType } from "../../../services/customTypes";

const storeOrderSlice = {
  initialState: {
    pickOrdersCount: 0,
    deliveryOrdersCount: 0,
    ordersPickup: [],
    ordersDelivery: [],
  },
  reducers: {
    setOrders: (state: any, action: any) => {
      const { pickupOrders, deliveryOrders } = action.payload;
      state.pickOrdersCount = pickupOrders.length;
      state.deliveryOrdersCount = deliveryOrders.length;
      state.ordersPickup = pickupOrders;
      state.ordersDelivery = deliveryOrders;
    },

    addOrderPickup: (state: any, action: any) => {
      state.ordersPickup.push(action.payload);
      state.pickOrdersCount += 1;
    },

    deleteOrderPickup: (state: any, action: any) => {
      const index = state.ordersPickup.findIndex(
        (order: any) => order.id === action.payload.id
      );
      if (index !== -1) {
        state.ordersPickup.splice(index, 1);
        state.pickOrdersCount -= 1;
      }
    },

    // updateOrderPickup: (state: any, action: any) => {
    //   console.log("payload update" , action.payload);

    //   const index = state.ordersPickup.findIndex(
    //     (order: any) => order.id === action?.payload?.order?.id
    //   );
    //   if (index !== -1) {
    //     state.ordersPickup[index] = {
    //       ...state.ordersPickup[index],
    //       ...action.payload?.order,
    //     };
    //   }
    // },

    updateOrderPickup: (state, action) => {
      console.log("payload update", action.payload);

      const updatedOrder = action.payload.order;

      // Filter out orders with invalid status
      const validStatuses = [
        OrderStatus.NOTASSIGNED,
        OrderStatus.CONFIRMED,
        OrderStatus.READY,
      ];

      if (!validStatuses.includes(updatedOrder.Status)) {
        // Remove the order if its status is invalid
        state.ordersPickup = state.ordersPickup.filter(
          (order: any) => order.id !== updatedOrder.id
        );
        state.pickOrdersCount = state.ordersPickup.length;
        return;
      }

      // Find the index of the existing order
      const index = state.ordersPickup.findIndex(
        (order: any) => order.id === updatedOrder.id
      );

      if (index !== -1) {
        // Update the existing order if found
        state.ordersPickup[index] = {
          ...state.ordersPickup[index],
          ...updatedOrder,
        };
      } else {
        // Add new order if not found (optional, based on the behavior you want)
        state.ordersPickup.push(updatedOrder);
        state.pickOrdersCount += 1;
      }
    },
    addOrderDelivery: (state: any, action: any) => {
      state.ordersDelivery.push(action.payload);
      state.deliveryOrdersCount += 1;
    },

    deleteOrderDelivery: (state: any, action: any) => {
      const index = state.ordersDelivery.findIndex(
        (order: any) => order.order.id === action.payload.order.id
      );
      if (index !== -1) {
        state.ordersDelivery.splice(index, 1);
        state.deliveryOrdersCount -= 1;
      }
    },

    // updateOrderDelivery: (state: any, action: any) => {
    //   console.log("payload update delivery" , action.payload);

    //   const index = state.ordersDelivery.findIndex(
    //     (order: any) => order.id === action?.payload?.order?.id
    //   );
    //   if (index !== -1) {
    //     state.ordersDelivery[index] = {
    //       ...state.ordersDelivery[index],
    //       ...action.payload?.order,
    //     };
    //   }
    // },
    updateOrderDelivery: (state, action) => {
      console.log("payload update delivery", action.payload);

      const updatedOrder = action.payload.order;

      // Filter out orders with invalid status
      const validStatuses = [OrderStatus.ASSIGNED, OrderStatus.CONFIRMED];

      if (!validStatuses.includes(updatedOrder.Status)) {
        // Remove the order if its status is invalid
        state.ordersDelivery = state.ordersDelivery.filter(
          (order: any) => order.id !== updatedOrder.id
        );
        state.deliveryOrdersCount = state.ordersDelivery.length;
        return;
      }

      // Find the index of the existing order
      const index = state.ordersDelivery.findIndex(
        (order: any) => order.id === updatedOrder.id
      );

      if (index !== -1) {
        // Update the existing order if found
        state.ordersDelivery[index] = {
          ...state.ordersDelivery[index],
          ...updatedOrder,
        };
      } else {
        // Add new order if not found (optional, based on the behavior you want)
        state.ordersDelivery.push(updatedOrder);
        state.deliveryOrdersCount += 1;
      }
    },

    processOrders: (state, action) => {
      const { orders, phoneStore } = action.payload.orders;

      // Reset the ordersPickup and ordersDelivery arrays
      state.ordersPickup = [];
      state.ordersDelivery = [];

      // Create a Set to keep track of processed order IDs
      const processedOrderIds = new Set();

      orders.forEach((order: any) => {
        const { id, OrderType, Status } = order;

        // Skip processing if the order has already been processed
        if (
          processedOrderIds.has(id) ||
          phoneStore != order?.Store?.phoneNumber
        ) {
          return;
        }

        // Mark the order as processed
        processedOrderIds.add(id);

        if (OrderType === "pickup") {
          // Keep only orders with status: NOTASSIGNED, CONFIRMED, READY
          if (
            Status === OrderStatus.NOTASSIGNED ||
            Status === OrderStatus.CONFIRMED ||
            Status === OrderStatus.READY
          ) {
            // Add new pickup order (no need to check for duplicates now, as duplicates are handled)
            state.ordersPickup.push(order);
          }
        } else if (OrderType === "delivery") {
          // Keep only orders with status: NOTASSIGNED, ASSIGNED, CONFIRMED, READY
          if (
            Status === OrderStatus.ASSIGNED ||
            Status === OrderStatus.CONFIRMED
          ) {
            // Add new delivery order (no need to check for duplicates now, as duplicates are handled)
            state.ordersDelivery.push(order);
          }
        }
      });

      // Update the counts after processing
      state.pickOrdersCount = state.ordersPickup.length;
      state.deliveryOrdersCount = state.ordersDelivery.length;
    },
  },
};

export default storeOrderSlice;

export const OMS_ORDERS_ADD_ALL_ORDERS = "omsOrders/addAllOrders";

export const OMS_ORDERS_EDIT_ORDER = "omsOrders/editOrder";

export const OMS_ORDERS_DELETE_ORDER = "omsOrders/deleteOrder";

export const OMS_ORDERS_RESET_ORDER = "omsOrders/reset";

export const OMS_ORDERS_UPDATE_RUNNER = "omsOrders/updateRunner";

export const OMS_ORDERS_UPDATE_ORDERLINE = "omsOrders/updateOrderLine";
