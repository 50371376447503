import socket from "./socket";

export function onSocketConnect() {
  console.log("socket" , socket);
  
  socket.on("connect", () => {
    console.log("Socket Connected =======================>", socket.id);
  });
}

export function onSocketConnectionError() {
  socket.on("connect_error", () => {
    socket.connect();
  });
}

export function onSocketDisconnect() {
  socket.on("disconnect", (reason : any) => {
    console.log("Socket Disconnected", reason);
  });
}
