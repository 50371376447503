
const ordersSlice ={
  initialState: {
    pickOrdersCount: 0,
    deliveryOrdersCount: 0,
  },
  reducers: {
    setOrders: (state : any, action : any) => {
      const { pickupOrders, deliveryOrders } = action.payload;
      state.pickOrdersCount = pickupOrders.length;
      state.deliveryOrdersCount = deliveryOrders.length;
    },
  },
};


export default ordersSlice;
