import  { useState } from 'react'

import './index.scss'
import { SidebarItemTypes } from './sidebar-entries'

interface SidebarPropsTypes {
  sideBarItemsList: SidebarItemTypes[]
}

function Sidebar({sideBarItemsList}: SidebarPropsTypes ) {


  const [selected, setSelected] = useState(sideBarItemsList[0]?.display)

  return {
    selected,
    setSelected,
    Render: (show: Boolean, callBack?: Function) => {
      return (
        <div id='Sidebar' className={`sidebar ${show ? "show" : ""}`}>
            
            <div className="sidebar-items">

              {sideBarItemsList.map((item, index)=> (
                <div
                key={index}
                className={`item ${item.display === selected ? 'selected' : ''}`}
                onClick={(e)=> {
                  e.preventDefault();
                  setSelected(item.display)
                  callBack instanceof Function && callBack()
                }}>
                  <img className='item-logo' src={item.display === selected ? item.activeIcon : item.icon} alt='sidebar-item-logo' />
                  <span></span>{item.label}
                </div>
              ))}

            </div>
          </div>
          )
        }
      }
}

export default Sidebar