import React from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import storeLogo from "../../assets/store-logo.svg";
import jwt_decode from "jwt-decode";

function ApplicationList() {
  const navigate = useNavigate();
  const token = localStorage?.getItem("token") ?? "";
  const decodedToken: any = Boolean(token) ?  jwt_decode(token) : null;
  const handleNavigate = () => {
    navigate("/app");
  };


  return (
    <div id="ApplicationList" className="row application-list-container">
      <span className="wlc-msg" data-testid="wlc-msg">
        Bonjour {decodedToken?.username}!
      </span>
      <div className="application-list">
        <div onClick={handleNavigate} className="app-box" data-testid="app-box">
          <div className="row">
            <img className="logo" src={storeLogo} alt="store logo" />
            <span>Mon Magazin</span>
          </div>
        </div>
        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>

        <div className="empty-app-box"></div>
      </div>
    </div>
  );
}

export default ApplicationList;
