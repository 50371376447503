import { ReactNode, useEffect, useRef } from "react";
import "./index.scss";

interface ModalPropsTypes {
  children: ReactNode;
  isConfirmed?: boolean;
  isReady?: boolean;
  onClose: () => void;
}

function Modal({
  children,
  isConfirmed = false,
  isReady = false,
  onClose,
}: ModalPropsTypes) {
  const containerClassName = `${
    isConfirmed ? "modalContainer-modal-confirmed" : "modalContainer"
  }${isReady ? " modalContainer-ready" : ""}`;

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="modalBackground">
      <div className={containerClassName} ref={modalRef}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
