import { useState, useEffect, useCallback } from "react";
import Masonry from "react-masonry-css";
import "./index.scss";
import OrderCard from "./order-card";
import OrderDetails from "./order-details";
import loader from "../../assets/loader.json";

import {
  OrderStatus,
  OrderType,
  OrdersType,
  emptyOrder,
} from "../../services/customTypes";
import { getAllOrders, getOrderLine } from "../../services/orders";
import Modal from "../modal";
import socket from "../../services/socket";
import { useSelector, useStore } from "react-redux";
import jwt_decode from "jwt-decode";
import { showSwalNotification } from "../../utils/swalUtils";
import Lottie from "lottie-react";
import empty from "../../assets/empty.json";
import CallButton from "../call-Button";
import orderInsertedSound from "./orderInserted.mp3";
import orderUpdated from "./orderUpdated.mp3";

function OrderList() {
  const [selectedOrder, setSelectedOrder] = useState(emptyOrder);
  const [orderList, setOrderList] = useState<OrdersType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const store = useStore();
  const filter = useSelector((state: Storage) => state?.entities?.filterSlice);
  const token = localStorage.getItem("token") ?? "";
  const decodedToken: any = token ? jwt_decode(token) : null;

  const handleCardClick = (order: OrdersType) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleOrderStatusUpdate = (updatedOrder: OrdersType) => {
    setOrderList((prevOrderList) => {
      const orderToUpdateIndex = prevOrderList.findIndex(
        (order) => order.id === updatedOrder.id
      );

      if (orderToUpdateIndex !== -1) {
        const newOrderList = [...prevOrderList];

        if (updatedOrder.OrderType === OrderType.PICKUP) {
          if (
            [
              OrderStatus.NOTASSIGNED,
              OrderStatus.READY,
              OrderStatus.CONFIRMED,
            ].includes(updatedOrder.Status as OrderStatus)
          ) {
            newOrderList[orderToUpdateIndex].Status = updatedOrder.Status;
          } else {
            newOrderList.splice(orderToUpdateIndex, 1);
          }
        } else if (updatedOrder.OrderType === OrderType.DELIVERY) {
          if (
            [OrderStatus.ASSIGNED, OrderStatus.CONFIRMED].includes(
              updatedOrder.Status as OrderStatus
            )
          ) {
            newOrderList[orderToUpdateIndex].Status = updatedOrder.Status;
          } else {
            newOrderList.splice(orderToUpdateIndex, 1);
          }
        }

        return newOrderList;
      } else {
        if (
          (updatedOrder.OrderType === OrderType.PICKUP &&
            updatedOrder.Status === OrderStatus.NOTASSIGNED) ||
          (updatedOrder.OrderType === OrderType.DELIVERY &&
            updatedOrder.Status === OrderStatus.ASSIGNED)
        ) {
          return [...prevOrderList, updatedOrder];
        }
      }

      return prevOrderList;
    });
  };

  const triggerSound = (soundFile: string) => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage("playSound");
    } else {
      const audio = new Audio(soundFile);
    }
  };
  
  function stopSound() {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage("stopSound");
    } else {
      console.error("ReactNativeWebView is not available");
    }
  }


  const handleOrderInsert = useCallback(
    async (order: any) => {
      if (
        order.OrderType.toUpperCase() === OrderType.PICKUP.toUpperCase() &&
        order.Store?.phoneNumber === decodedToken?.store?.phoneNumber
      ) {
        try {
          const response = await getOrderLine(order.id);
          order.orderLine = [response.data.content.orderLine];
          setOrderList((prevOrderList) => [...prevOrderList, order]);
          triggerSound(orderInsertedSound);
        } catch (error) {
          console.error("Failed to retrieve orderLine:", error);
        }
      }
    },
    [decodedToken]
  );

  const handleOrderUpdate = useCallback(
    async (updatedOrder: any) => {
      if (
        !updatedOrder.orderLine &&
        [OrderStatus.ASSIGNED, OrderStatus.NOTASSIGNED].includes(
          updatedOrder.Status
        ) &&
        updatedOrder.Store?.phoneNumber === decodedToken?.store.phoneNumber
      ) {
        try {
          const response = await getOrderLine(updatedOrder.id);
          updatedOrder.orderLine = [response.data.content.orderLine];
        } catch (error) {
          console.error("Failed to retrieve orderLine:", error);
        }
      }

      handleOrderStatusUpdate(updatedOrder);

      const message =
        updatedOrder.OrderType === OrderType.DELIVERY
          ? `Une nouvelle commande Pour la livraison a été ajoutée avec le numéro <strong class="text-bold">${updatedOrder.orderNumber}</strong>.`
          : `Une nouvelle commande pour ramassage a été ajoutée avec le numéro <strong class="text-bold">${updatedOrder.orderNumber}</strong>.`;

      showSwalNotification({
        html: message,
        customClass: {
          timerProgressBar:
            updatedOrder.OrderType === OrderType.DELIVERY
              ? "bg-deliverycustom"
              : "bg-custom",
        },
      });

      triggerSound(orderUpdated);
    },
    [decodedToken, handleOrderStatusUpdate]
  );

  useEffect(() => {
    getAllOrders(setOrderList, setIsLoading);
    socket.on("orderUpdate", handleOrderUpdate);
    socket.on("orderInsert", handleOrderInsert);

    return () => {
      socket.off("orderUpdate", handleOrderUpdate);
      socket.off("orderInsert", handleOrderInsert);
      setOrderList([]);
    };
  }, []);

  useEffect(() => {
    console.log("order list" , orderList , orderList.filter(
      (order) => order.OrderType === OrderType.PICKUP
    ),);
    
    if (orderList?.length > 0 ) {
      store.dispatch({
        type: "ordersSlice/setOrders",
        payload: {
          pickupOrders: orderList.filter(
            (order) => order.OrderType === OrderType.PICKUP
          ).length,
          deliveryOrders: orderList.filter(
            (order) => order.OrderType === OrderType.DELIVERY
          ).length,
        },
      });
    }else{
      store.dispatch({
        type: "ordersSlice/setOrders",
        payload: {
          pickupOrders: 0,
          deliveryOrders: 0,
        },
      });
    }
  }, [orderList.length]);

  const filteredOrders = orderList.filter((order) =>
    filter === "all" ? true : order.OrderType === filter
  );

  return (
    <div id="OrderList" className="order-list" data-testid="order-list">
      {filteredOrders.length > 0 ? (
        <Masonry
          breakpointCols={{ default: 4, 1400: 3, 1200: 2, 800: 1 }}
          className="card-list"
          columnClassName="my-masonry-grid_column"
        >
          {filteredOrders.map((order: OrdersType) => (
            <OrderCard
              key={order.id}
              order={order}
              onClickCard={() => handleCardClick(order)}
              onOrderStatusUpdate={handleOrderStatusUpdate}
            />
          ))}
        </Masonry>
      ) : !isLoading ? (
        <div className="lottie-wrapper">
          <Lottie className="lottie-animation" animationData={empty} loop />
        </div>
      ) : null}

      {isLoading && (
        <div className="loader">
          <Lottie animationData={loader} loop autoplay />
        </div>
      )}

      {modalOpen && (
        <Modal
          isConfirmed={selectedOrder?.Status === OrderStatus.CONFIRMED}
          isReady={selectedOrder?.Status === OrderStatus.READY}
          onClose={() => setModalOpen(false)}
        >
          <OrderDetails
            order={selectedOrder}
            closeOrderClick={setModalOpen}
            onOrderStatusUpdate={handleOrderStatusUpdate}
          />
        </Modal>
      )}

      <CallButton />
    </div>
  );
}

export default OrderList;
