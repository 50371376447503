import { OrderStatus } from "../services/customTypes";

export const getStatusColor = (orderStatus: any) => {
  let color = "";

  switch (orderStatus) {
    case OrderStatus.TAKEN:
      color = "#E2FFF9"; 
      break;
    case OrderStatus.PICKED:
      color = "#E2FFF9";
      break;
    case OrderStatus.READY:
      color = "#F4FFFD"; 
      break;
    case OrderStatus.DELIVERED:
      color = "#74DBC2"; 
      break;
    case OrderStatus.CANCELED:
      color = "#FFC8C8"; 
      break;
    case OrderStatus.REJECTED:
      color = "#FFC8C8"; 
      break;
    default:
      color = "#000000"; 
  }

  return color;
};
