import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import HistoryItem from "./history-item";
import { getHistoryOrders } from "../../services/orders";
import Modal from "../modal";
import OrderHistoryDetails from "../order-list/order-details/history-details";
import Lottie from "lottie-react";
import loader from "../../assets/loader.json";
import { OrdersType } from "../../services/customTypes";
import empty from "../../assets/empty.json";
import { translateOrderStatus } from "../../utils/translations";
import { getStatusColor } from "../../utils";
import QrIcon from "./../../assets/QrIcon.svg";
import ModalQr from "../ModalQr";
import QRCodePrintComponent from "../QRcode-Print";

function HistoryList() {
  const [orderList, setOrderList] = useState<OrdersType[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OrdersType | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [qrIsOpen, setQrIsOpen] = useState(false);
  const [opendOrderQr, setOpendOrderQr] = useState<any>(null);

  const containerRef = useRef(null);

  const loadMoreOrders = async () => {
    if (page < totalPages && !isLoading) {
      setIsLoading(true);
      try {
        const response = await getHistoryOrders(50, page + 1);
        if (response?.success) {
          setPage(page + 1);
          setOrderList((prevOrders) => [
            ...prevOrders,
            ...response?.data?.content?.orders,
          ]);
        }
      } catch (error) {
        console.error("Failed to load more orders:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 800);
      }
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await getHistoryOrders(50, page);
        setIsLoading(false);

        if (response?.success) {
          setOrderList(response?.data?.content?.orders);
          setTotalPages(response?.data?.content?.totalPages);
        }
      } catch (error) {
        setIsLoading(false);

        console.error("Failed to fetch orders:", error);
      }
    };

    fetchOrders();

    return () => {
      setOrderList([]);
      setPage(1);
      setTotalPages(0);
    };
  }, []);

  const formatDateTime = (createdAt: any) => {
    if (!createdAt) return ""; // Return empty string if createdAt is undefined or null

    const date = new Date(createdAt); // Create a Date object from the createdAt value
    const dateString = `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`; // Format date as DD/MM/YYYY
    const timeString = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`; // Format time as HH:MM

    return `${dateString} - ${timeString}`; // Return formatted date with time
  };
  const openQrCode = (order: any) => {
    console.log("yess open qr");

    setOpendOrderQr(order);
    setQrIsOpen(true);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    order: any
  ) => {
    event.preventDefault(); // Prevent default form behavior
    event.stopPropagation(); // Stop event from bubbling up

    openQrCode(order); // Call your action
  };
  const closeQrCode = () => {
    setQrIsOpen(false);
  };

  return (
    <div className="HistoryListContainer">
      <table className="table-style">
        <thead style={{ height: 60 }}>
          <tr>
            <th className="title-txt">Qr</th>
            <th className="title-txt">N°</th>
            <th className="title-txt">Type</th>
            <th className="title-txt">Date</th>
            <th className="title-txt"></th>
          </tr>
        </thead>

        <tbody className="t-body" onScroll={loadMoreOrders}>
          {orderList?.length > 0 ? (
            orderList
              ?.map((order: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="line-table-style"
                    onClick={() => setSelectedOrder(order)}
                  >
                    <td className="td-right">
                      <img
                        src={QrIcon}
                        onClick={(e: any) => handleClick(e, order)}
                      />
                    </td>

                    <td className="td-center">
                      <span className="td-center-txt">
                        {Boolean(order?.orderNumber)
                          ? order?.orderNumber
                          : "Not set yet"}
                      </span>
                    </td>
                    <td className="td-center-type">
                      <span className="td-center-txt">{order?.OrderType}</span>
                    </td>
                    <td className="td-left">
                      <span className="td-left-txt">
                        {formatDateTime(order?.createdAt)}
                      </span>
                    </td>
                    <td className="td-right-status">
                      <span
                        className="td-right-text-style"
                        style={{
                          backgroundColor: getStatusColor(order?.Status),
                        }}
                      >
                        <span>{translateOrderStatus(order?.Status)}</span>
                      </span>
                    </td>
                  </tr>
                );
              })
              .reverse()
          ) : (
            <></>
          )}
        </tbody>
      </table>
      {isLoading && (
        <div className="loader">
          <Lottie animationData={loader} loop autoplay />
        </div>
      )}
      {selectedOrder && (
        <Modal onClose={() => setSelectedOrder(null)}>
          <OrderHistoryDetails
            order={selectedOrder}
            closeOrderClick={setSelectedOrder}
          />
        </Modal>
      )}
      {qrIsOpen && (
        <ModalQr onClose={() => closeQrCode()}>
          <QRCodePrintComponent order={opendOrderQr} />
        </ModalQr>
      )}
    </div>
  );
}

export default HistoryList;
