import { useEffect } from "react";
import { useAuth } from "../services/auth";
import jwt_decode from "jwt-decode";
const token = localStorage?.getItem("token") ?? "";
const decodedToken: any = Boolean(token) ? jwt_decode(token) : null;

const TokenExpirationChecker = () => {
  const { logout, getToken } = useAuth();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = getToken();
      if (token) {
        const expirationTime = decodedToken?.exp * 1000;
        if (expirationTime <= Date.now()) {
          logout();
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [logout, getToken]);

  return null;
};

export default TokenExpirationChecker;
