import Swal from "sweetalert2";

export const showSwalNotification = (options : any) => {
  Swal.fire({
    timerProgressBar: true,
    position: "bottom-left",
    timer: 5000,
    color: "#000000",
    toast: true,
    showConfirmButton: false,
    ...options,
  });
};
