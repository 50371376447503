// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background: #159b7b; /* Set the background color and transparency */
  border: 1px transparent #ccc;
  padding: 7px;
  z-index: 1000;
  padding: 20px;
  /* Add any additional styling as needed */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 999; /* Lower z-index than the popup */
}

.modal-container__content {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Jitsi/index.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA,EAAA,8CAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,yCAAA;AAAJ;;AAGE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA,EAAA,6BAAA;EACA,YAAA,EAAA,iCAAA;AAAJ;;AAGE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":["// styles/index.scss\n.custom-popup {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 80%;\n    height: 80%;\n    background: #159b7b ; /* Set the background color and transparency */\n    border: 1px transparent #ccc;\n    padding: 7px;\n    z-index: 1000; // Ensure the popup is above other elements\n    padding: 20px;\n    /* Add any additional styling as needed */\n  }\n  \n  .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */\n    z-index: 999; /* Lower z-index than the popup */\n  }\n  \n  .modal-container__content {\n    width: 100%;\n    height: 100%;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
