// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  height: 100px;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.header .logo-section {
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.header .info-section {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin-top: 1%;
  justify-content: flex-end;
}
.header .info-section .day {
  font-weight: 300;
}
.header .info-section .rest {
  font-weight: 700;
}
.header .info-section .weather-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
}
.header .user-section {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.header .user-section .username {
  margin-left: 10px;
  margin-top: 3%;
}
.header .user-section .profile-img {
  margin-right: 10px;
}
.header .logo-img {
  height: 500px;
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/welcome-screen/header/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,2CAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,gBAAA;AADN;AAII;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAJJ;AAMI;EACE,iBAAA;EACA,cAAA;AAJN;AAOI;EACE,kBAAA;AALN;AASE;EACE,aAAA;EACA,WAAA;AAPJ","sourcesContent":[".header {\n  height: 100px;\n  display: flex;\n  position: relative;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n\n  .logo-section {\n    display: flex;\n    align-items: center;\n    padding-left: 30px;\n  }\n\n  .info-section {\n    display: flex;\n    align-items: center;\n    padding-bottom: 12px;\n    font-family: \"Montserrat\";\n    font-style: normal;\n    font-weight: 300;\n    font-size: 20px;\n    line-height: 24px;\n    margin-top: 1%;\n    justify-content: flex-end;\n\n    .day {\n      font-weight: 300;\n    }\n\n    .rest {\n      font-weight: 700;\n    }\n\n    .weather-value {\n      font-family: \"Montserrat\";\n      font-style: normal;\n      font-weight: 700;\n    }\n  }\n\n  .user-section {\n    display: flex;\n    align-items: center;\n    font-family: \"Montserrat\";\n    font-style: normal;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 24px;\n\n    .username {\n      margin-left: 10px;\n      margin-top: 3%;\n    }\n\n    .profile-img {\n      margin-right: 10px;\n    }\n  }\n\n  .logo-img {\n    height: 500px;\n    width: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
