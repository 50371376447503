import { createSlice } from "@reduxjs/toolkit";
import * as slicesReducers from "./reducers";

const sliceCreator = (name : any, _createSlice : any, _slicesReducers : any = {}) => {
  const sliceReducers = _slicesReducers[name];

  return _createSlice({
    name: name,
    ...sliceReducers,
  });
};

export const createSlices = (slicesArray : any) => {
  return slicesArray.reduce((accumulator :any , sliceName :any) => {
    const elementSlice = sliceCreator(sliceName, createSlice, slicesReducers);
    accumulator[sliceName] = elementSlice;
    return accumulator;
  }, {});
};
