import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/auth";
import { translateMessage } from "../../../utils/translations";
import QrReader from "react-qr-scanner";
import Webcam from "react-webcam";
import "./index.scss";
import { showSwalNotification } from "../../../utils/swalUtils";
import { Scanner } from "@yudiel/react-qr-scanner";
const LoginForm = () => {
  const [signInError, setSignInError] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const {token, setToken } = useAuth() 


  const handleScan = async (result: any) => {
    if (result && !isProcessing) {
      setIsProcessing(true);
      try {
        const isLoggedIn = await auth.login({
          qrCode: result?.text,
          appName: "Store",
        });
        console.log('is login ' ,isLoggedIn );
        

        if (isLoggedIn.success) {
          console.log("yesss ==========>");
          
          showSwalNotification({
            html: "Connecté avec succès !",
            customClass: {
              htmlContainer: "html-text",
              timerProgressBar: "bg-QRcustom-Success",
            },
          });

          // navigate("/", { replace: true });

          navigate("/app");
        } else {
          showSwalNotification({
            html: translateMessage(isLoggedIn.msg),
            customClass: {
              htmlContainer: "html-text",
              timerProgressBar: "bg-QRcustom",
            },
          });
        }
      } catch (error) {
        showSwalNotification({
          html: "Une erreur s'est produite lors de la connexion.",
          customClass: {
            timerProgressBar: "bg-QRcustom",
          },
        });
      } finally {
        setIsProcessing(false); // Reset after processing
      }
    }
  };

  const handleConnexion = () => {
    setShowQrScanner(true);
    // handleScan({ text: "80b93b86fc59dcc76160" });
  };

  return (
    <div id="LoginForm" className="login-form">
      <Formik initialValues={{ appName: "" }} onSubmit={() => {}}>
        {() => (
          <Form>
            <div className="form-input" data-testid="QrScanner">
              {/* Render QrReader only if showQrScanner is true */}
              {showQrScanner ? (
                // <QrReader
                //   delay={100}

                //   onError={handleError}
                //   onScan={handleScan}
                // />
                <Scanner
                  onScan={(result: any) =>
                    handleScan({ text: result?.[0]?.rawValue })
                  }
                  scanDelay={100}
                />
              ) : (
                <div className="unsupported-message">
                  This browser does not support scanning QR codes.
                </div>
              )}
              {/* Alternative UI for browsers that do not support getUserMedia */}
            </div>
            <div className="login-submit">
              {!showQrScanner && (
                <button
                  type="button"
                  disabled={isProcessing}
                  className="login-button"
                  data-testid="Connexion"
                  onClick={handleConnexion}
                >
                  {isProcessing ? "Connexion en cours..." : "Connexion"}
                </button>
              )}
            </div>
            {signInError && (
              <div className="sign-in-error">
                <h6>{signInError}</h6>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
