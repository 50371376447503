// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.call-button .icon {
  margin-right: 8px;
  width: 60px;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/call-Button/index.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACF,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAAF;AACI;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AACN","sourcesContent":["// CallButton.scss\n.call-button {\n    position: fixed;\n  bottom: 20px;  \n  right: 20px;   \n  display: flex;\n  align-items: center;\n  padding: 10px 15px;\n  background-color: transparent;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n    .icon {\n      margin-right: 8px; \n      width: 60px;\n      height: 80px; \n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
