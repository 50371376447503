import React, { useEffect } from "react";
import "./index.scss"; // Adjust the path
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface JitsiMeetPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const JitsiMeetPopup: React.FC<JitsiMeetPopupProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    let jitsiApi: any;

    const initializeJitsi = () => {
      const domain = "meet.jit.si";
      const roomName = "PizzaMeet";

      const options = {
        roomName,
        width: "100%",
        height: "100%",
        userInfo: {
          displayName: "PizzaStore",
        },
        parentNode: document.getElementById("modal-content"),
      };

      // Check if the modal content is present
      const modalContent = document.getElementById("modal-content");
      if (!modalContent) {
        console.error("Modal content not found.");
        return;
      }

      options.parentNode = modalContent; // Set parentNode to the modal content

      jitsiApi = new window.JitsiMeetExternalAPI(domain, options);

      // Close the modal when the Jitsi Meet window is closed
      jitsiApi.addListener("videoConferenceLeft", handleClose);
    };

    const handleClose = () => {
      onClose();
    };

    if (isOpen && window.JitsiMeetExternalAPI) {
      initializeJitsi();
    }

    return () => {
      if (jitsiApi) {
        jitsiApi.dispose();
      }
    };
  }, [isOpen, onClose]);

  const handleOverlayClick = (event: React.MouseEvent) => {
    // Close the popup when clicking outside of it (on the overlay)
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div data-testid="jitsi-meet-popup" style={{ backgroundColor: "red" }}>
      {/* Overlay for clicking outside the popup */}
      {/* {isOpen && <div className="overlay" data-testid="overlay" onClick={handleOverlayClick}></div>} */}

      {/* Custom Popup */}
      {isOpen && (
        <div className="custom-popup" data-testid="modal-container">
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: 2,
              right: 2,
              zIndex: 2,
            }}
            onClick={onClose}
          >
            <FontAwesomeIcon
              className="close-menu-icon-app-history"
              style={{ fontSize: 24 }}
              icon={icon({ name: "xmark" })}
            />
          </div>
          <div id="modal-content" className="modal-container__content"></div>
          {/* You can add a close button or other UI elements here */}
        </div>
      )}
    </div>
  );
};

export default JitsiMeetPopup;
