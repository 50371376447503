import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import "./index.scss";
import menuHamburger from "../../../../assets/menu-hamburger.svg";
import logo from "../../../../assets/sidebar/logo-small.svg";
import storeLogo from "../../../../assets/sidebar/store-active.svg";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode";

interface HeaderPropTypes {
  isMenuOpen: Boolean;
  menuToggle: Function;
}

function HeaderHistory({ isMenuOpen, menuToggle }: HeaderPropTypes) {
  const [currentDateTime, setCurrentDateTime] = useState(
    moment().format("dddd, D MMMM YYYY - HH:mm")
  );
  const token = localStorage?.getItem("token") ?? "";

  const decodedToken: any = Boolean(token) ?  jwt_decode(token) : null;
  console.log("fff" , decodedToken);
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().format("dddd, D MMMM YYYY - HH:mm"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleToggleMenu = () => {
    menuToggle((prev: boolean) => !prev);
  };
  const [day, rest] = currentDateTime.split(", ");
  return (
    <div className="header-app-history">
      <div className="right-section-app-history">
        <div className="logo-app-history">
          <img className="logo-img-app-history" src={logo} alt="logo" />
        </div>

        <div className="title-app-history">
          <img src={storeLogo} alt="store" />
          <span>{decodedToken?.store?.name}</span>
        </div>

        <button className="menu-toggle-app-history" onClick={handleToggleMenu}>
          {!isMenuOpen ? (
            <img src={menuHamburger} alt="hamburger" />
          ) : (
            <FontAwesomeIcon
              className="close-menu-icon-app-history"
              icon={icon({ name: "xmark" })}
            />
          )}
        </button>
      </div>
      <div className="left-section-app-history" data-testid="current-datetime">
        <span className="day-app-history">{day} , </span>
        <span className="rest-app-history">{rest}</span>
      </div>
    </div>
  );
}

export default HeaderHistory;
